module.exports = {
  siteTitle: 'Martin Vlad',
  siteDescription:
    'Martin Vladimirov is a full stack web developer based in San Jose, CA who specializes in building (and occasionally designing) exceptional websites, applications, and everything in between.',
  siteKeywords:
    'Martin Vladimirov, Martin, Vlad, martinvlad, software engineer, front-end engineer, web developer, javascript, western',
  siteUrl: 'https://martinvlad.com',
  siteLanguage: 'en_US',
  name: 'Martin Vladimirov',
  location: 'San Jose, CA',
  email: 'martingvlad@gmail.com',
  github: 'https://github.com/martinvlad',
  twitterHandle: '@martingvlad',
  socialMedia: [
    {
      name: 'GitHub',
      url: 'https://github.com/martinvlad',
    },
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/martin-vladimirov-0242b0160/',
    },
    {
      name: 'Instagram',
      url: 'https://www.instagram.com/martinvlad1k',
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/martingvlad1',
    },
  ],

  navLinks: [
    {
      name: 'About',
      url: '/#about',
    },
    {
      name: 'Work Experience',
      url: '/#jobs',
    },
    {
      name: 'Projects',
      url: '/#projects',
    },
    {
      name: 'Contact',
      url: '/#contact',
    },
  ],

  navHeight: 100,

  colors: {
    green: '#64ffda',
    navy: '#0a192f',
    darkNavy: '#020c1b',
  },

  srConfig: (delay = 100) => ({
    origin: 'bottom',
    distance: '20px',
    duration: 500,
    delay,
    rotate: {
      x: 0,
      y: 0,
      z: 0,
    },
    opacity: 0,
    scale: 1,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    mobile: true,
    reset: false,
    useDelay: 'always',
    viewFactor: 0.25,
    viewOffset: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  }),
};
