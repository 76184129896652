import React from 'react';

const IconLoader = () => (
  <svg
    id="logo"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    width="261.021"
    height="298.536">
    <title>Logo animation</title>
    <g>
      <g id="N" transform="translate(37.000000, 33.000000)">
        <text
          textAnchor="start"
          fontFamily="Acme"
          fontSize="60"
          y="35"
          x="-13"
          strokewidth="4"
          stroke="#f50551"
          fill="#f50551">
          M
        </text>
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
